import createStorage from 'utils/storage'
import axios from 'axios'
import numeral from 'numeral'
import store from '@/store'
import { DocumentProcessTypes } from 'utils/commonValue'

export const cdnUrl = url => process.env.VUE_APP_CDN_URL + url
export const signFromLink = () => {
  const storage = createStorage('sessionStorage')
  if (storage.get('LINK_KEY')) {
    return [true, storage.get('LINK_KEY'), storage.get('CONTACT_INFO'), storage.get('SIGN_METHOD_TYPE')]
  } else {
    return [false]
  }
}

export const file2Base64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = () => {
      reject(new Error('Read file Error'))
    }
  })
}

export const clearLinkSessions = () => {
  const storage = createStorage('sessionStorage')
  storage.set('LINK_KEY', '')
  storage.set('CONTACT_INFO', '')
  storage.set('SIGN_METHOD_TYPE', '')
}

export const getSvgSignature = (type, base64Img) => {
  const width = type === 'stamp' ? 125 : 260
  const height = type === 'stamp' ? 125 : 100
  return `<svg width="${width}" height="${height}" version="1.1" viewBox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <image width="${width}" height="${height}" xlink:href="${base64Img}" /></svg>`
}

export const downloadFile = (url, fileName = 'download') => {
  return axios({
    url,
    method: 'get',
    responseType: 'blob'
  }).then(res => {
    convertRes2Blob(res, fileName)
  })
}

export const convertRes2Blob = (response, fileName) => {
  // 将二进制流转为blob
  const blob = new Blob([response.data], { type: 'application/octet-stream' })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
    window.navigator.msSaveBlob(blob, decodeURI(fileName))
  } else {
    // 创建新的URL并指向File对象或者Blob对象的地址
    const blobURL = window.URL.createObjectURL(blob)
    // 创建a标签，用于跳转至下载链接
    const tempLink = document.createElement('a')
    tempLink.href = blobURL

    // filtering inappbrowser
    if (navigator.userAgent.match(/inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|iPhone(.*)Whale|Android(.*)Whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|SamsungBrowser\/[^1]/i)) {
      tempLink.href = response.config.url
    }

    tempLink.setAttribute('download', decodeURI(fileName))
    // 兼容：某些浏览器不支持HTML5的download属性
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    // 释放blob URL地址
    window.URL.revokeObjectURL(blobURL)
  }
}

export const HTMLDecode = (text) => {
  if (text === null || text === undefined || text === '') {
    return ''
  }
  if (typeof text !== 'string') {
    return String(text)
  }
  let temp = document.createElement('div')
  temp.innerHTML = text
  const output = temp.textContent || temp.innerText
  temp = null
  return output
}

export const formatNum = num => {
  return numeral(num).format('0,0')
}

export const getTeamId = () => {
  if (store.state.user.userInfo && store.state.user.userInfo.organization) {
    return store.state.app.selectedTeam ? store.state.app.selectedTeam.teamId : store.state.user.userInfo.organization.teamId
  } else {
    return ''
  }
}

export const isOrgUser = () => {
  return store.state.user.userInfo && store.state.user.userInfo.organization !== undefined
}

export const hasCurTeamAllPermission = (curTeamId = '') => {
  const organization = store.state.user.userInfo.organization
  if (organization && curTeamId) {
    const curUserTeamId = organization.teamId
    const roleId = organization.roleId
    if (roleId === '1000' || roleId === '1001') return true
    if (roleId === '1002') {
      if (organization.permsScope === 1 && organization.permsGroupId === '1000') return true
      if (organization.permsScope === 2 && organization.permsGroupId === '1000') return true
      if (organization.permsScope === 2 && organization.permsGroupId === '1001' && curTeamId === curUserTeamId) return true
    }
    return false
  } else {
    return true
  }
}

export const canSign = (docStatus, currentSigningOrder, participants, processType, isFromLink = false, contactInfo = '') => {
  const userInfo = store.state.user.userInfo
  // 문서 상태가 진행중인가?
  if (docStatus === 'on_going') {
    if (processType === DocumentProcessTypes.PROCEDURE) {
      const curParticipant = participants.find(item => item.signingOrder === currentSigningOrder)
      // 참여자가 현재 참여자인가?
      if (curParticipant) {
        // 문서가 외부 참여자인가?
        if (isFromLink) {
          // 문서가 대면서명인가? - 서명 불가
          if (processType === DocumentProcessTypes.FACE_TO_FACE) {
            return {
              can: false,
              reason: 'signerError'
            }
          }
          // 참여자의 유효기간이 만료되었는가?
          if (!curParticipant.signingExpiry.valid) {
            return {
              can: false,
              reason: 'signExpiry'
            }
          } else {
            // 참여자의 이메일주소, 휴대폰번호가 일치하는가?
            const can = contactInfo.toLowerCase() === curParticipant.signingContactInfo.toLowerCase()
            return {
              can,
              reason: can ? 'success' : 'signerError'
            }
          }
        } else { // 외부링크 접속자가 아닌가?
          // 참여자가 이메일타입이고 회원의 이메일주소가 참여자의 이메일 주소와 일치하는가?
          if (curParticipant.signingMethodType === 'email' && userInfo.email.toLowerCase() === curParticipant.signingContactInfo.toLowerCase()) {
            // 참여자가 서명유효기간 만료상태가 아닌가? - 서명가능
            if (curParticipant.signingExpiry.valid) {
              return {
                can: true,
                reason: 'success'
              }
            } else {
              // 만료 상태인가? - 서명불가
              return {
                can: false,
                reason: 'signExpiry'
              }
            }
          } else if (curParticipant.signingMethodType === 'kakao' && userInfo.phone === curParticipant.signingContactInfo) { // 참여자가 휴대폰타입이고 회원의 휴대폰번호가 참여자의 휴대폰 번호와 일치하는가?
            // 참여자가 서명 유효기간 만료상태가 아닌가? - 서명 가능
            if (curParticipant.signingExpiry.valid) {
              return {
                can: true,
                reason: 'success'
              }
            } else {
              // 만료 상태인가? - 서명불가
              return {
                can: false,
                reason: 'signExpiry'
              }
            }
          } else if (processType === DocumentProcessTypes.FACE_TO_FACE) { // 대면서명 타입인가? - 서명 가능
            return {
              can: true,
              reason: 'success'
            }
          } else { // 모두 아닐경우 - 서명 불가
            return {
              can: false,
              reason: 'signerError'
            }
          }
        }
      } else { // 참여자순서가 아닌가? - 서명불가
        return {
          can: false,
          reason: 'signerError'
        }
      }
    } else if (processType === DocumentProcessTypes.FACE_TO_FACE) {
      if (userInfo) {
        const curParticipant = participants.find(item => item.signingOrder === currentSigningOrder)
        if (curParticipant.signingExpiry.valid) {
          return {
            can: true,
            reason: 'success'
          }
        } else {
          // 만료 상태인가? - 서명불가
          return {
            can: false,
            reason: 'signExpiry'
          }
        }
      } else {
        return {
          can: false,
          reason: 'not-allowed'
        }
      }
    } else {
      return {
        can: false,
        reason: 'processTypeError'
      }
    }
  } else if (docStatus === 'completed') { // 서명이 완료 상태인가? - 서명 불가
    return {
      can: false,
      reason: 'completed'
    }
  } else { // 기타 서명 상태인가 ?  - 상태이상 에러
    return {
      can: false,
      reason: 'docStatusError'
    }
  }
}

export const genKey = (n = 8) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyz'
  const length = chars.length
  let key = ''
  for (let i = 0; i < n; i++) {
    key += chars.charAt(Math.floor(Math.random() * length))
  }
  return key
}

export const clearUserInfo = () => {
  store.commit('setToken', '')
  store.commit('setRefreshToken', '')
  store.commit('setUserInfo', '')
  store.dispatch('setTeam', '')
}

export const removeNullValue = (obj) => {
  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key]
    }
  }
  return obj
}
