export const userToken = 'UCS_TOKEN'
export const refreshToken = 'UCS_REF'
export const userInfo = 'UCS_USER_INFO'
export const savedUserId = 'UCS_SAVED_USER_ID'
export const savedMainId = 'UCS_SAVED_MAIN_ID'
export const savedSubId = 'UCS_SAVED_SUB_ID'
export const locale = 'LOCALE'
export const signerColor = ['#27d0b0', '#ffca10', '#6dacf7', '#cd75ce', '#a5d46e']
export const bannerLastClosedAt = 'bannerLastClosedAt'
export const currentPdf = 'CURRENTPDF'
export const hideSigningTutorial = 'hideSigningTutorial'
export const hideSignCreatingTutorial = 'hideSignCreatingTutorial'
export const tooltipPayment = 'tooltipPayment'
export const tooltipSignstart = 'tooltipSignStart'
export const tooltipMobileSignCreating = 'tooltipMobileSignCreating'
export const bulkRequestNotice = 'bulkRequestNotice'
export const tooltipPaymentModal = 'tooltipPaymentModal'
export const SIGNATURE_BYTE_URL = {
  local: 'http://localhost:8080/v1/files/signature/',
  development: 'https://devapp.ucansign.com/v1/files/signature/',
  production: 'https://app.ucansign.com/v1/files/signature/'
}
export const LOGO_BYTE_URL = {
  local: 'http://localhost:8080/v1/files/logo/',
  development: 'https://devapp.ucansign.com/v1/files/logo/',
  production: 'https://app.ucansign.com/v1/files/logo/'
}
/*
released current notice version
NOTI1_VERSION : 2.9
NOTI2_VERSION : 0
NOTI3_VERSION : 0
*/
export const NOTI1_VERSION = 0
export const NOTI2_VERSION = 0
export const NOTI3_VERSION = 0
